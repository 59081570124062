import './App.css';
import Landing from './pages/Landing.jsx';

function App() {
  return (
    <div className="App">
      <Landing/>
    </div>
  );
}

export default App;
