import React from 'react'
import logo from '../LOGO1.svg';
import logo2 from '../LOGO2.svg';
import logo3 from '../LOGO3.svg';


export default function Landing() {
  return (
    <div className='Landing'>
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p className='logo-name'>STERRENWACHT</p>
        </header>

        <div className='logo2'>
            <img src={logo2} className='logo-2' alt="" />
        </div>
        <div className='logo3'>
            <img src={logo3} className='logo-3' alt="" />
        </div>

        <section className='landing-text'>
            <p>Sterrenwacht is het eerste ouderinitiatief in Brussel voor actieve jongvolwassenen met een beperking. Sterrenwacht wil een brugfunctie invullen tussen het schoolverlaten en het functioneren in de ‘volwassenen wereld’ voor jongeren 16+ en hun ouders/begeleiders. Deze brugfunctie naar de andere levensdomeinen is om onze jongeren de mogelijkheid te bieden om actief en inclusief te laten participeren in de maatschappij. Op deze manier willen we hen behoeden voor sociaal isolement.</p>
        </section>
        <section className='landing-visie'>
            <h2>Visie</h2>
            <p>
                We gaan uit van de eigen kracht en talenten van elke jongere. We geloven in hun ontplooiing op hun eigen, zelf te bepalen tempo. Daarbij neemt verbinding met anderen een centrale plek in. 
                <br /><br />
                Álle jongeren (met een beperking) hebben recht op lokale dagbesteding waarin zij zich goed voelen, en waarbij ze gewoon zichzelf kunnen zijn. 
            </p>
        </section>
        <section className='landing-missie'>
            <h2>Missie</h2>
            <p>
                We willen kwaliteitsvolle dagbesteding op maat aanbieden aan actieve jongeren (met een beperking). Dat impliceert dat de jongeren zelf mee aan het roer staan, en mee het aanbod ontwikkelen.
                <br /><br />
                We willen hen een veilige plek bieden, hen in verbinding brengen met anderen, zodat ze niet in sociaal isolement terechtkomen. 
                <br /><br />
                We willen hen stimuleren hun talenten te ontdekken en te ontwikkelen, zonder hen te duwen in de één of andere richting.
                <br /><br />
                We willen helpen om een springplank op maat te maken voor de jongeren, samen met de nodige partners, op weg naar de inclusieve wereld. 
                <br /><br />
                Sterrenwacht fungeert als facilitator, niet als stuurman.    
                <br /><br />
                We willen een breed en laagdrempelig infokanaal zijn van ouders voor ouders. 
                <br /><br />
                We zetten in op de betrokkenheid van familie en het persoonlijk netwerk

            </p>
        </section>
        <section className='landing-vacatuur'>
            <h2>Vacature Sterrenwachter</h2>
            <p>
            Sterrenwacht gelooft in de kracht van zinvolle dagbesteding om het mentaal welzijn van actieve jongvolwassenen met een beperking te versterken: activiteiten op maat van Brusselse jongeren en uit de rand, in de buurt, op het kruispunt met cultuur, sport, of in de natuur. We zijn op zoek naar onze eerste medewerker die instaat voor een laagdrempelig onthaal voor de jongeren, maar ook om informatie te geven aan hun ouders en het organiseren van gerichte activiteiten in overleg met de cliënten.
            </p>
            <h3>Context:</h3>
            <p>
            Sterrenwacht (het eerste ouderinitiatief van Brussel voor actieve jongvolwassenen met een beperking) wil ervoor zorgen dat schoolverlaters van BUSO niet in een zwart gat vallen en achter de muren van hun kamer verdwijnen door actieve dagbesteding aan te bieden (zowel individueel als collectief) voor dezen die geen plekje vonden in een maatwerkbedrijf of vrijwilligerswerk . Maar ook voor deze voor wie een voltijds arbeidsklimaat te zwaar is om te dragen. 
            <br /><br />
            Sterrenwacht wil activiteiten organiseren in overleg met de cliënten en vertrekkende vanuit hun persoonlijke interesses en leefwereld. Sterrenwacht heeft een brugfunctie tussen het schoolverlaten en het functioneren in de 'volwassenenwereld' voor jongeren 16+ en voor hun ouders/begeleiders. Deze brugfunctie met de andere levensdomeinen wijst op de verantwoordelijkheid die we dragen om jongeren niet op te sluiten tussen de 4 muren van hun kamer, maar actief en inclusief laten participeren in de maatschappij.
            <br /><br />
            Daarom is het ook zo belangrijk dat de ouders goed en zo volledig als mogelijk geïnformeerd worden over de reële bestaande mogelijkheden voor jongeren en waar ze terecht kunnen (of niet)
            </p>
            <h3>Functieomschrijving en takenpakket:</h3>
            <p>
            De Sterrenwachter realiseert, in overleg met en met de steun van een bestuurder, een kwaliteitsvol onthaal, plant en begeleidt zinvolle diverse cultuur en sportieve dagactiviteiten en welzijnsgerichte projecten. Vergaart alle nodige informatie van de sociale kaart van het Brusselse landschap en de rand.
            Als Sterrenwachter plan je onthaalmomenten, collectieve en individuele welzijnsgerichte trajecten in een divers en complementair team. Je begeleidt en zorgt voor opvolging, hebt contact met gespecialiseerde partners en bent actief op verschillende welzijnsdomeinen.
            </p>
            <h3>Taken:</h3>
            <ul>
                <li>Je helpt mee aan de opbouw van een hele nieuwe organisatie</li>
                <li>Je begeleidt zelfstandig kleine groepen.</li>
                <li>Je werkt inhoudelijk een atelier uit</li>
                <li>Je organiseert op regelmatige wijze onthaalmomenten, instuif, culturele en sportieve activiteiten. </li>
                <li>Vanuit de laagdrempelige werking leg je contact met jongeren en hun ouders. Je creëert een veilig kader en bouwt een vertrouwensband met hen op.</li>
                <li>Je ondersteunt en realiseert collectieve projecten op maat. </li>
                <li>Je waakt mee over het mentaal welbevinden van de cliënten. </li>
                <li>Je helpt mee een netwerk uitbouwen van lokale partners. </li>
                <li>Je staat mee in voor de nodige rapportage. </li>
                <li>Je handelt volgens het deontologisch kader van Sterrenwacht. </li>
                <li>Je werkt voornamelijk overdag maar af en toe ook eens in het weekend. Ook tijdens vakantieperiodes.</li>
            </ul>

            <h3>Profiel:</h3>
            <ul>
                <li>Je bent flexibel en beschikbaar, geëngageerd en hebt kennis van het doelpubliek. Je bent als persoon steeds in evolutie en gaat hiernaar ook actief op zoek. </li>
                <li>Je hebt kennis van de sociale kaart van Brussel. </li>
                <li>Je straalt een grote toegankelijkheid uit. </li>
                <li>Je weet hoe jongeren op een fijne manier te onthalen. Je bent in staat een zinvol activiteitenaanbod uit te bouwen. </li>
                <li>Je analyseert de noden van jongeren en werkt procesmatig. </li>
                <li>Je werkt planmatig. </li>
                <li>Je spreekt vlot Nederlands en Frans en kan schriftelijke administratie in het Nederlands opvolgen. Kennis van andere talen is een extra troef.</li>
            </ul>

            <h3>Ons aanbod: </h3>
            <ul>
                <li>We verwelkomen je in een warm nest in een inspirerende en inclusieve omgeving. Daarnaast waarderen we jouw inzet en speelt jouw impact een belangrijke rol in de weg naar een inclusieve maatschappij.</li>
                <li>Onmiddellijke indiensttreding of zodra beschikbaar. </li>
                <li>Een halftijds contract van onbepaalde duur (50%VTE). </li>
                <li>Correcte verloning conform PC 329.01. Relevante anciënniteit wordt in aanmerking genomen. </li>
                <li>Een aantrekkelijk verlofpakket dat groeit als je langer in dienst bent.</li>
                <li>Constante groeimogelijkheden.</li>
                <li>Openbaar vervoer-abonnement voor woon-werkverkeer 100% terugbetaald. Je krijgt ook een telefoonvergoeding. </li>
                <li>Een gevarieerde job, met ruimte voor eigen initiatief en zelfontplooiing in een door waarden gedreven Brusselse organisatie met een hoge maatschappelijke relevantie</li>
            </ul>

            <p>
            Wij zijn geïnteresseerd in je kwaliteiten, ongeacht je leeftijd, gender, handicap, etnische afkomst en nationaliteit.
            <br /><br />
            Interesse?
            <br /> <br />
            Stuur je cv en een motivatiebrief naar: niki.v@sterrenwacht.org<br /> Voor meer informatie over de job, neem contact op met Niki Vranken 0475 95 83 72
            <br /><br />
            Deze vacature kan ook gecombineerd worden met de vacature van Brake Out Brussel Trainer: <a href="https://konekt.be/nl/nieuws/vacature-trainer-brake-out-brussel">https://konekt.be/nl/nieuws/vacature-trainer-brake-out-brussel</a> <br />De tewerkstelling is op dezelfde locatie.            <br /><br /><br /><br /><br />

            <br /><br />
            Kort samengevat zoeken we iemand die:
            <br /><br />
            zelfstandig kan werken
            <br />
            evenementen organiseren
            <br />
            activiteiten plannen en begeleiden
            <br />
            Brusselse context kent
            <br />
            met de voeten in het veld staat
            <br />
            ad hoc oplossingen vindt
            <br />
            af en toe WE werk doet
            <br />
            opvolging/connectie met ouders
            <br />
            begeleiden van ouders in aanvragen dossiers
            <br />
            laagdrempelig werkt
            <br />
            sociale kaart van Brussel kent
            <br />
            kan praten voor publiek
            <br />
            een hart heeft voor alle mensen
            <br />
            digitaal sterk is &gt;&gt;&gt; sociale media
            <br />
            NL (+ FR)
            </p>

        </section>
        <section className='landing-contact'>
            <p>
                admin@sterrenwacht.org <br />
                0470 700 944
                <br /><br />
                Groot-Bijgaardenstraat 481 - 1082 Sint-Agatha-Berchem 
                <br /><br />
                BUS: 20, 87  -  halte/arrêt :BROEK <br />
                TRAM: 19  -  halte/arrêt : HUNDERENVELD
            </p>
        </section>

    </div>
  )
}
